import { Box } from "@mui/system";
import JobDetailEditableFormView from "./JobDetailEditableFormView";

export default function JobDetailEditable() {
  return (
    <Box paddingBottom="52px">
      <JobDetailEditableFormView />
    </Box>
  );
}
